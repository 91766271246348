<template>
  <div class="outletStatus">
    <div class="container">
      <b-container class="d-flex justify-content-end">
        <!-- 戻るボタン -->
        <div class="mb-3" style="margin-right: auto;margin-left: -15px;">
          <b-button type="button" 
            variant="dark" 
            v-on:click="onBack" class="b-button-back"
          >戻る</b-button>
        </div>

        <!-- 更新ボタン -->
        <div class="mb-3">
          <b-button
            class="reload"
            size=""
            variant="dark"
            v-on:click="reload"
            >
              <b-icon icon="arrow-clockwise" :animation="reloadAnimation" font-scale="1"></b-icon>
            </b-button
          >
        </div>

        <!-- 機器情報編集ボタン -->
        <div class="mb-3">
          <b-button
            id="button"
            class="control-btn"
            size=""
            :to="`/rebooter/edit/${rebooterId}`"
            variant="primary"
            v-show="isShowChange"
            >機器情報編集</b-button
          >
        </div>

        <div class="mb-3">
          <!-- ステータス更新ボタン -->
          <modalComponent
            :isDisabled="isDisabledChangeUpdateStatusButton()"
            buttonTitle="ステータス更新"
            class="control-btn"
            operationTitle="リブーターのステータス取得"
            :operationComment="` ${this.items2[0].shopName} 拠点のリブーターのステータスを取得しますが、よろしいでしょうか。`"
            acceptTitle="リブーターのステータス取得"
            :acceptComment="getMessage()"
            @response="getResponse"
            @responseButtonTitle="getResponseButtonTitle"
          ></modalComponent>
        </div>
      </b-container>
      <div class="d-flex justify-content-end">
        <div class="annotation">※ステータス更新ボタンを押すと、リブーターの状態を取得します</div>
      </div>

      <div class="mb-1">
        <label v-text="`リブーターの状態 :`"></label>
        <label v-text="`${rebooterStatus}`" v-bind:class="rebooterStatusClass"></label>
      </div>

      <div class="mb-1">
        <label v-text="`取得日時 : ${updatedAt}`"></label>
      </div>
    </div>

    <div class="container">
      <b-table class="control-table" striped hover :items="row1" :fields="fields1">
        
        <!-- 状態 -->
        <template v-slot:cell(outletState)="data">
          <span class="d-inline-block" v-bind:class="row1[data.index].tdClass">
              {{ data.value }}
          </span>
        </template>

        <!-- 操作 -->
        <template #cell(outletControl)="data">
          <b-container class="d-flex justify-content-start">
            <!-- ONボタン -->
            <div class="modalComponent" @click="clickCellButton(data)">
              <modalComponent
                :isDisabled="isDisabledChange(data)"
                buttonTitle="ON"
                operationTitle="リブーターの電源操作"
                :operationComment="` ${items2[0].shopName} 拠点の[ ${
                  row1[data.index].outletName
                } ]の電源をONにしますがよろしいでしょうか`"
                acceptTitle="リブーターの電源操作"
                :acceptComment="getMessage()"
                @response="getResponse"
                @responseButtonTitle="getResponseButtonTitle"
              ></modalComponent>
            </div>

            <!-- OFFボタン -->
            <div class="modalComponent" @click="clickCellButton(data)">
              <modalComponent
                :isDisabled="isDisabledChange(data)"
                buttonTitle="OFF"
                operationTitle="リブーターの電源操作"
                :operationComment="` ${items2[0].shopName} 拠点の[ ${
                  row1[data.index].outletName
                } ]の電源をOFFにしますがよろしいでしょうか`"
                acceptTitle="リブーターの電源操作"
                :acceptComment="getMessage()"
                @response="getResponse"
                @responseButtonTitle="getResponseButtonTitle"
              ></modalComponent>
            </div>
          </b-container>
        </template>
      </b-table>
    </div>

    <!-- 機器詳細の表 -->
    <b-table stacked :items="items2" :fields="fields2"></b-table>
  </div>
</template>

<script>
// モーダルコンポーネントのインポート
import modal from "@/components/modal/modal.vue";
import api from "../../services/api";
import moment from "moment";

export default {
  // タグ付け
  components: {
    modalComponent: modal,
  },

  data() {
    return {
      response: {},

      responseButtonTitle: "",

      rowNumber: "",

      params: {},

      role: "",

      show: true,

      message: "",

      // リブーターの状態
      rebooterStatus: "",
      rebooterStatusClass: "",

      // 取得日時
      updatedAt: "",

      // リブーターの状態
      rebooterStatusEnum: {
        active: "稼働中",
        disconnect: "非疎通",
        suspend: "使用停止中",
        inprogress: "操作中",
      },

      // 使用停止フラグのON,OFF
      exclusionFlagEnum: {
        0: "OFF",
        1: "ON",
      },

      // 操作中フラグ
      operationFlag: null,

      // リブーターID
      rebooterId: "",

      //リブートタップ電源操作表の行
      fields1: [
        { key: "outletName", label: "名称" },
        { key: "outletState", label: "状態" },
        { key: "outletControl", label: "操作" },
      ],

      //リブーター電源操作表の列
      row1: [
        {
          ID: 1,
          outletName: "",
          outletState: "",
          tdClass: ""
        },
        {
          ID: 2,
          outletName: "",
          outletState: "",
          tdClass: ""
        },
        {
          ID: 3,
          outletName: "",
          outletState: "",
          tdClass: ""
        },
        {
          ID: 4,
          outletName: "",
          outletState: "",
          tdClass: ""
        },
        { ID: "-", outletName: "全アウトレット", outletState: "-", getAt: "-" },
      ],

      fields2: [
        { key: "shopName", label: "拠点" },
        { key: "manufacturer", label: "機種" },
        { key: "remarks", label: "備考" },
        { key: "macAddress", label: "MACアドレス" },
        { key: "ipAddress", label: "IPアドレス" },
        { key: "sendMailaddress", label: "送信先メールアドレス" },
        { key: "exclusionFlag", label: "使用停止フラグ" },
      ],

      //リブートタップの詳細情報のパラメーター
      items2: [
        {
          shopName: "",
          manufacturer: "",
          remarks: "",
          macAddress: "",
          ipAddress: "",
          sendMailaddress: "",
          controlId: "",
          controlPassword: "",
          exclusionFlag: "",
        },
      ],

      // 更新ボタン
      reloadAnimation: ""
    };
  },

  methods: {
    getResponse(obj) {
      this.response = obj;

      // 押されたボタンの行と種類でパラメーターを設定
      switch (this.rowNumber) {
        // 一行目
        case 0:
          if (this.responseButtonTitle == "ON") {
            // 操作ONパラメータ
            this.params = {
              outlet1Status: "ON",
            };
          } else if (this.responseButtonTitle == "OFF") {
            // 操作OFFパラメータ
            this.params = {
              outlet1Status: "OFF",
            };
          }
          break;
        // 二行目
        case 1:
          if (this.responseButtonTitle == "ON") {
            // 操作ONパラメータ
            this.params = {
              outlet2Status: "ON",
            };
          } else if (this.responseButtonTitle == "OFF") {
            // 操作OFFパラメータ
            this.params = {
              outlet2Status: "OFF",
            };
          }
          break;
        // 三行目
        case 2:
          if (this.responseButtonTitle == "ON") {
            // 操作ONパラメータ
            this.params = {
              outlet3Status: "ON",
            };
          } else if (this.responseButtonTitle == "OFF") {
            // 操作OFFパラメータ
            this.params = {
              outlet3Status: "OFF",
            };
          }
          break;
        // 四行目
        case 3:
          if (this.responseButtonTitle == "ON") {
            // 操作ONパラメータ
            this.params = {
              outlet4Status: "ON",
            };
          } else if (this.responseButtonTitle == "OFF") {
            // 操作OFFパラメータ
            this.params = {
              outlet4Status: "OFF",
            };
          }
          break;
        // 五行目(全アウトレット)
        default:
          if (this.responseButtonTitle == "ON") {
            // 操作ONパラメータ
            this.params = {
              outlet1Status: "ON",
              outlet2Status: "ON",
              outlet3Status: "ON",
              outlet4Status: "ON",
            };
          } else if (this.responseButtonTitle == "OFF") {
            // 操作OFFパラメータ
            this.params = {
              outlet1Status: "OFF",
              outlet2Status: "OFF",
              outlet3Status: "OFF",
              outlet4Status: "OFF",
            };
          } else if (this.responseButtonTitle == "ステータス更新") {
            // ステータス取得パラメータ
            this.params = {};
          }
          break;
      }
      // OKボタン押下時のみ設定したパラメータをリクエストとして送る
      if (this.response.result == true) {
        // リブーター操作処理
        api
          .postRebooterControl(this.rebooterId, this.params)
          .then((result) => {
            // 万が一失敗したらトーストを出す
            if (result.status === 200) {
              this.message = "操作を受け付けました。";
            } else {
              this.message = JSON.stringify(result.data.result);
            }
            
            // 初期化
            this.params = {};
            this.rowNumber = "";
          })
          .catch((err) => {
            // エラーログを出すこと
            console.log(err);
          });
      }
    },

    // クリックしたボタンがON,OFFか判別するためにボタンタイトルを取得
    getResponseButtonTitle(obj) {
      this.responseButtonTitle = obj;
    },

    // クリックしたON,OFFボタンのあるセルの行データを取得
    clickCellButton(data) {
      this.rowNumber = data.index;
    },

    // クリックしたON,OFFボタンのあるセルの行データを取得
    getMessage() {
      return this.message;
    },

    // リブーターの状態、口数によって、アウトレット3,4名称のON,OFFボタンを非活性にする
    isDisabledChange(data) {
      switch (this.rebooterStatus) {
        case "使用停止中":
          return true;
        case "操作中":
          return true;
        case "非疎通":
          if (this.outletCount == 2) {
            if (data.index == 2 || data.index == 3) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        case "稼働中":
          if (this.outletCount == 2) {
            if (data.index == 2 || data.index == 3) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        default:
          break;
      }
    },

    // リブーターの状態、口数によって、ステータス更新ボタンを非活性にする
    isDisabledChangeUpdateStatusButton() {
      switch (this.rebooterStatus) {
        case "使用停止中":
          return true;
        case "操作中":
          return true;
        case "非疎通":
          return false;
        case "稼働中":
          return false;
        default:
          break;
      }
    },

    // 口数が2の時の描画設定処理
    outletNameAndStateForCount2() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = "";
      this.row1[3].outletName = "";

      // リブーターの状態
      this.row1[0].outletState = this.rebooter.rebootersStatus.outlet1Status;
      this.row1[1].outletState = this.rebooter.rebootersStatus.outlet2Status;
      this.row1[2].outletState = "なし";
      this.row1[3].outletState = "なし";

      // ONまたはOFFを大文字で表記する
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
        if (this.row1[index].outletState === "off") {
          this.row1[index].tdClass = "error";
        }
        this.row1[index].outletState = this.row1[index].outletState.toUpperCase();
      })
    },

    // 口数が4の時の描画設定処理
    outletNameAndStateForCount4() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = this.rebooter.outlet3Name;
      this.row1[3].outletName = this.rebooter.outlet4Name;

      // リブーターの状態
      this.row1[0].outletState = this.rebooter.rebootersStatus.outlet1Status;
      this.row1[1].outletState = this.rebooter.rebootersStatus.outlet2Status;
      this.row1[2].outletState = this.rebooter.rebootersStatus.outlet3Status;
      this.row1[3].outletState = this.rebooter.rebootersStatus.outlet4Status;

      // ONまたはOFFを大文字で表記する
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
        if (this.row1[index].outletState === "off") {
          this.row1[index].tdClass = "error";
        }
        this.row1[index].outletState = this.row1[index].outletState.toUpperCase();
      })
    },

    // 非疎通かつ口数が2の時の描画設定処理
    disconnectOutletNameAndStateForCount2() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = "";
      this.row1[3].outletName = "";

      // リブーターの状態
      this.row1[0].outletState = "不明";
      this.row1[1].outletState = "不明";
      this.row1[2].outletState = "なし";
      this.row1[3].outletState = "なし";

      // classを初期化
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
      });
    },

    // 非疎通かつ口数が4の時の描画設定処理
    disconnectOutletNameAndStateForCount4() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = this.rebooter.outlet3Name;
      this.row1[3].outletName = this.rebooter.outlet4Name;

      // リブーターの状態
      this.row1[0].outletState = "不明";
      this.row1[1].outletState = "不明";
      this.row1[2].outletState = "不明";
      this.row1[3].outletState = "不明";
      
      // classを初期化
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
      });
    },

    // 使用停止中かつ口数が2の時の描画設定処理
    suspendOutletNameAndStateForCount2() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = "";
      this.row1[3].outletName = "";

      // リブーターの状態
      this.row1[0].outletState = "停止";
      this.row1[1].outletState = "停止";
      this.row1[2].outletState = "なし";
      this.row1[3].outletState = "なし";
      
      // classを初期化
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
      });
    },

    // 使用停止中かつ口数が4の時の描画設定処理
    suspendOutletNameAndStateForCount4() {
      // リブーターのアウトレット名称
      this.row1[0].outletName = this.rebooter.outlet1Name;
      this.row1[1].outletName = this.rebooter.outlet2Name;
      this.row1[2].outletName = this.rebooter.outlet3Name;
      this.row1[3].outletName = this.rebooter.outlet4Name;

      // リブーターの状態
      this.row1[0].outletState = "停止";
      this.row1[1].outletState = "停止";
      this.row1[2].outletState = "停止";
      this.row1[3].outletState = "停止";

      // classを初期化
      this.row1.map((item, index) => {
        this.row1[index].tdClass = "";
      });
    },

    async init() {
      //const id = this.$route.params.id;
      this.rebooterId = this.$route.params.id;

      // リブーター検索
      await api
        .getRebooter(this.rebooterId)
        .then((result) => {
          this.rebooter = result.data.rebooter;

          // アウトレット口数
          this.outletCount = this.rebooter.outletCount;

          // 操作中フラグ
          this.operationFlag = this.rebooter.rebootersStatus.operationFlag;

          // 操作中フラグがON(1)のとき
          if (this.operationFlag == 1) {
            // リブーターの状態は操作中のみ
            this.rebooterStatus = this.rebooterStatusEnum["inprogress"];
            // 操作中フラグがOFF(0)のとき
          } else if (this.operationFlag == 0) {
            // リブーターの状態は非疎通、使用停止中、稼働中のどれかのみ
            this.rebooterStatus =
              this.rebooterStatusEnum[
                this.rebooter.rebootersStatus.rebooterStatus
              ];
          }

          // 描画制御
          switch (this.rebooterStatus) {
            case "非疎通":
              this.rebooterStatusClass = "rebooter_status rebooter_disconnect";
              if (this.outletCount == 2) {
                this.disconnectOutletNameAndStateForCount2();
              } else if (this.outletCount == 4) {
                this.disconnectOutletNameAndStateForCount4();
              }
              break;
            case "稼働中":
              this.rebooterStatusClass = "rebooter_status rebooter_active";
              if (this.outletCount == 2) {
                this.outletNameAndStateForCount2();
              } else if (this.outletCount == 4) {
                this.outletNameAndStateForCount4();
              }
              break;
            case "操作中":
              this.rebooterStatusClass = "rebooter_status rebooter_operation";
              // リブーターのステータスが非疎通時の場合
              if (this.rebooter.rebootersStatus.rebooterStatus == "disconnect") {
                if (this.outletCount == 2) {
                  this.disconnectOutletNameAndStateForCount2();
                } else if (this.outletCount == 4) {
                  this.disconnectOutletNameAndStateForCount4();
                }                
                break;
              }

              if (this.outletCount == 2) {
                this.outletNameAndStateForCount2();
              } else if (this.outletCount == 4) {
                this.outletNameAndStateForCount4();
              }
              break;
            case "使用停止中":
              this.rebooterStatusClass = "rebooter_status rebooter_suspend";
              if (this.outletCount == 2) {
                this.suspendOutletNameAndStateForCount2();
              } else if (this.outletCount == 4) {
                this.suspendOutletNameAndStateForCount4();
              }
              break;
            default:
              break;
          }

          // 取得日時(YYYY-MM-DD HH:mm:ss形式)
          this.updatedAt = this.rebooter.rebootersStatus.updatedAt;
          this.updatedAt = moment
            .utc(this.rebooter.rebootersStatus.updatedAt)
            .add(9, "hours")
            .format("YYYY-MM-DD HH:mm:ss");

          // リブーターの詳細情報
          this.items2[0].shopName = this.rebooter.shopName;
          this.items2[0].manufacturer = this.rebooter.manufacturer;
          this.items2[0].remarks = this.rebooter.remarks;
          this.items2[0].macAddress = this.rebooter.macAddress;
          this.items2[0].ipAddress = this.rebooter.ipAddress;
          this.items2[0].sendMailaddress = this.rebooter.sendMailaddress;
          this.items2[0].controlId = this.rebooter.controlId;
          this.items2[0].controlPassword = this.rebooter.controlPassword;
          this.items2[0].exclusionFlag = this.rebooter.exclusionFlag;

          // 使用停止フラグON,OFF表示のための処理
          this.items2.map((item, index) => {
            this.items2[index].exclusionFlag =
              this.exclusionFlagEnum[item.exclusionFlag];
          });
          
        })
        .catch((err) => {
          // エラーログを出すこと
          console.log(err);
        });
    },

    // 再取得
    reload() {
      this.reloadAnimation = "spin";
      this.init();
      setTimeout(() => {
        this.reloadAnimation = "";
      }, 1000);
    },

    // 戻る
    onBack() {
      this.$router.push("/rebooter/view");
    }

  },

  async mounted() {
    await this.init();
    api
      .authorization()
      .then((result) => {
        this.role = result.data.result.role;
      })
      .catch((err) => {
        // エラーログを出すこと
        console.log(err);
      });
  },

  computed: {
    // 権限がuserの場合は、機器登録、ユーザー管理を非表示にする
    isShowChange() {
      if (this.role == "admin") {
        return this.show == true;
      } else {
        return this.show == false;
      }
    },
  },
};
</script>

<style>
.control-table td .error {
  color: #FF0000;
  font-weight: bold;
}
button.btn.reload {
  margin-right: 10px;
}
.reload:focus, 
.reload:active:focus {
  box-shadow: none;
}

label.rebooter_status {
  padding: 1px 5px;
  margin-left: 5px;
  width: 100px;
  text-align: center;
}

label.rebooter_active {
  background-color: #067626;
  color: #FFFFFF;
}

label.rebooter_disconnect {
  background-color: #FF0000;
  color: #FFFFFF;
}

label.rebooter_suspend {
  background-color: #AAAAAA;
  color: #FFFFFF;
}

label.rebooter_operation {
  background-color: #000000;
  color: #FFFFFF;
}


@media screen and (max-width:480px) {
  .control-btn {
    width: 90px;
  }
}
</style>
<style scoped>
/* ボタンidのスタイル */
#button {
  margin-right: 10px;
}

/* ボタンクラスのスタイル */
.button {
  margin-right: 10px;
}

/* モーダルクラスのスタイル */
.modalComponent {
  margin-right: 10px;
}

.annotation {
  color: #FF0000;
  font-size: 15px;
}
</style>
