<template>
  <div class="input-form">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="b-form">

      <!-- 戻るボタン -->
      <div style="display: inline-flex">
        <b-button type="button" variant="dark" v-on:click="onBack" class="b-button-submit"
          >戻る</b-button>
      </div>
      
      <b-form-group
        class="b-form-group"
        id="input-group-1"
        label="ユーザー名"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.userName"
          :state="userNameFormState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="ユーザー名を入力して下さい"
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-2"
        label="会社名"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.companyName"
          :state="companyNameFormState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="会社名を入力して下さい"
          trim
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-3"
        label="部署名"
        label-for="input-3"
      >
        <b-form-input
          id="input-3"
          v-model="form.departmentName"
          :state="departmentNameFormState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="部署名を入力して下さい"
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-4"
        label="メールアドレス"
        label-for="input-2"
      >
        <div v-if="mode === 'create'">
            <b-form-input
              id="input-4"
              v-model="form.mailaddress"
              :state="mailaddressFormState"
              aria-describedby="input-live-help input-live-feedback"
              type="email"
              placeholder="メールアドレスを入力してください"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              100文字以内で入力してください
            </b-form-invalid-feedback>
        </div>
        <div v-if="mode === 'edit'">
            <b-form-input
              id="input-4"
              v-model="form.mailaddress"
              :state="mailaddressFormState"
              aria-describedby="input-live-help input-live-feedback"
              type="email"
              placeholder="メールアドレスは変更できません"
              disabled
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              100文字以内で入力してください
            </b-form-invalid-feedback>
        </div>
      </b-form-group> 

      <b-form-group
        id="input-group-5"
        label="権限"
        label-for="input-5"
        class="b-form-group"
      >
        <b-form-select
          id="input-5"
          v-model="form.role"
          :options="role"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-6"
        label="無効フラグ"
        label-for="input-6"
        class="b-form-group"
      >
        <b-form-checkbox
          id="input-6"
          v-model="form.invalidFlag"
          value="1"
          unchecked-value="0"
        ></b-form-checkbox>
      </b-form-group>

      <div style="display: inline-flex">
        <div v-if="mode === 'edit'">
          <b-button
            v-on:click="onSubmit"
            variant="primary"
            class="b-button-submit"
            :disabled="!isInputValidate"
            >更新</b-button
          >
        </div>

        <div v-if="mode === 'create'">
          <b-button
            v-on:click="onSubmit"
            variant="primary"
            class="b-button-submit"
            :disabled="!isInputValidate"
            >登録</b-button
          >
        </div>

        <b-button type="reset" variant="primary" class="b-button-reset"
          >リセット</b-button
        >
        <!-- 削除ボタン -->
        <div v-if="mode === 'edit'">
          <b-button
            type="button"
            variant="danger"
            class="b-button-delete"
            v-on:click="onDeleteModal"
            :disabled="isLoginUser"
            >削除</b-button
          >
        </div>
      </div>
      
      <b-modal
      v-model="bulkOperation.modalShow"
      :title="'ユーザー削除'"
      size="lg"
      >
        <div class="bulk_operation_modal">
          <div v-if="bulkOperation.stage === 0">
            <div class="header">
              <p>
                ユーザーを削除します。よろしいでしょうか。
              </p>
            </div>
          </div>
          <div v-if="bulkOperation.stage === 9">
            <div class="content">
              <div>
                <p>操作を中断しました。</p>
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <b-button @click="onDelete" variant="primary">OK</b-button>
          <b-button
            @click="bulkOperation.modalShow = !bulkOperation.modalShow"
            v-if="
                bulkOperation.stage !== 9
            "
            >Cancel</b-button
          >
        </template>
      </b-modal>
    </b-form>
  </div>
</template>


<script>
import api from "../../../services/api";

export default {
  props: ["form", "mode", "id"],

  data() {
    return {
      role: [
        { text: "付与する権限をお選びください", value: "" },
        { text: "管理者", value: "admin" },
        { text: "一般", value: "user" },
      ],
      show: true,
      disabledFlag: false,
      
      // モーダル操作用
      bulkOperation: {
        // モーダル表示フラグ
        modalShow: false,
        // 処理ステージ
        stage: 0,
        // 一括操作バッチID
        id: "",
      },
    };
  },

  computed: {
    // ユーザー名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    userNameFormState() {
      return this.form.userName.length <= 50 ? null : false;
    },

    // 会社名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    companyNameFormState() {
      return this.form.companyName.length <= 50 ? null : false;
    },

    // 部署名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    departmentNameFormState() {
      return this.form.departmentName.length <= 50 ? null : false;
    },

    // メールアドレスが100文字以上入力されたときに、アラートを出すためにfalseを返す
    mailaddressFormState() {
      return this.form.mailaddress.length <= 100 ? null : false;
    },

    // 入力バリデーションチェック結果
    isInputValidate() {
      return this.userNameFormState == null &&
        this.companyNameFormState == null &&
        this.departmentNameFormState == null &&
        this.mailaddressFormState == null && this.disabledFlag === false;
    },

    // ログインユーザ判定
    isLoginUser(){
      return this.id === String(localStorage.getItem("userId"))
    }
  },

  methods: {
    // 登録
    onSubmit(event) {
      this.disabledFlag = true;
      event.preventDefault();
      if (
        this.userNameFormState == null &&
        this.companyNameFormState == null &&
        this.departmentNameFormState == null &&
        this.mailaddressFormState == null
      ) {
        // 登録パラメータ
        const params = {
          userName: this.form.userName,
          companyName: this.form.companyName,
          departmentName: this.form.departmentName,
          mailaddress: this.form.mailaddress,
          role: this.form.role,
          invalidFlag: this.form.invalidFlag === "1" ? "1" : "0",
        };

        if (this.mode == "create") {
          // 登録処理
          api
            .postUser(params)
            .then((result) => {
              // 登録が成功したら一覧画面へ戻る
              if (result.status === 200) {
                // 一覧画面へ遷移
                this.disabledFlag = false;
                this.$router.push("/admin/user/view");
              } else {
                // 登録失敗
                this.disabledFlag = false;
                alert(JSON.stringify(result.data.result));
              }
            })
            .catch((err) => {
              // エラーログを出すこと
              this.disabledFlag = false;
              console.log(err);
            });
        } else if (this.mode == "edit") {
          // 更新処理
          api
            .putUser(this.id, params)
            .then((result) => {
              // 更新が成功したら一覧画面へ戻る
              if (result.status === 200) {
                // 一覧画面へ遷移
                this.disabledFlag = false;
                this.$router.push("/admin/user/view");
              } else {
                // 更新失敗
                this.disabledFlag = false;
                alert(JSON.stringify(result.data.result));
              }
            })
            .catch((err) => {
              // エラーログを出すこと
              this.disabledFlag = false;
              console.log(err);
            });
        }
      } else {
        // ここに入った場合、何もおきない
      }
    },

    // 入力リセット処理
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.userName = "";
      this.form.companyName = "";
      this.form.departmentName = "";
      this.form.mailaddress = "";
      this.form.role = "";
      this.form.invalidFlag = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // 戻る
    onBack() {
      this.$router.push("/admin/user/view");
    },

    // ユーザー削除モーダル起動
    onDeleteModal() {
      // 処理ステージを初期化
      this.bulkOperation.stage = 0;
      // モーダルを表示
      this.bulkOperation.modalShow = !this.bulkOperation.modalShow;
    },
  
    // ユーザー削除
    onDelete() {
      // ユーザー削除
      api
        .deleteUser(this.id)
        .then((result) => {
          if(result.status === 200){
            alert("削除しました。");
            this.onBack();
          } else {
              // 削除失敗
              this.disabledFlag = false;
              // alert(JSON.stringify(result.data.result));
              alert("削除に失敗しました。");
            // モーダルを非表示
            this.bulkOperation.modalShow = false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  },
};
</script>

<style scoped>
.b-form-group {
  margin: 20px 50px 20px;
}

.b-button-register {
  margin: 20px 50px 20px;
}

.b-button-submit {
  margin: 0px 10px 0px 50px;
}

.b-button-delete{
  margin: 0px 10px 0px 10px;
}
.Result {
  margin: 20px 50px 20px;
}

.input-form {
  margin: 10px 130px 0px 130px;
}

.btn_left {
  text-align: left;
  float: left;
}

.btn_right {
  text-align: right;
  float: right;
  margin-right: 50px;
  margin-left: 10px;
}
  
</style>
