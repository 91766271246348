<template>
  <div class="input-form">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="b-form">
      
      <!-- 戻るボタン -->
      <div style="display: inline-flex">
        <b-button type="button" variant="dark" v-on:click="onBack" class="b-button-back"
          >戻る</b-button>
      </div>
      
      <b-form-group
        class="b-form-group"
        id="input-group-1"
        label="MACアドレス"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.macAddress"
          :state="macAddressFormState"
          type="url"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="00:00:00:00:00:00"
          pattern="(?:[0-9a-fA-F]{2}¥:){5}[0-9a-fA-F]{2}"
          title="MACアドレスは｢XX:XX:XX:XX:XX:XX｣の形式で入力してください。"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          MACアドレスは｢XX:XX:XX:XX:XX:XX｣の形式で入力してください。
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-2"
        label="IPアドレス"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.ipAddress"
          :state="ipAddressFormState"
          type="url"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="127.0.0.1"
          pattern="^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$"
          title="IPアドレスは｢XXX.XXX.XXX.XXX｣の形式で入力してください。"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          IPアドレスは｢XXX.XXX.XXX.XXX｣の形式で入力してください。
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-3"
        label="送信先メールアドレス"
        label-for="input-3"
      >
        <b-form-input
          id="input-3"
          v-model="form.sendMailAddress"
          placeholder="メールアドレスはシステムにて自動生成されます"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="機種"
        label-for="input-4"
        class="b-form-group"
      >
        <b-form-select
          id="input-4"
          v-model="form.manufacturer"
          :options="manufacturer"
          @change="outletNameClear"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        label="拠点"
        label-for="input-5"
        class="b-form-group"
      >
        <b-form-input
          id="input-5"
          v-model="form.shopName"
          :state="shopNameFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="拠点名を入力してください"
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          80文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-6"
        label="アウトレット1名称"
        label-for="input-6"
      >
        <b-form-input
          id="input-6"
          v-model="form.outlet1Name"
          :state="outlet1NameFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="アウトレット1の名称を入力してください"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-7"
        label="アウトレット2名称"
        label-for="input-7"
      >
        <b-form-input
          id="input-7"
          v-model="form.outlet2Name"
          :state="outlet2NameFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="アウトレット2の名称を入力してください"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-8"
        label="アウトレット3名称"
        label-for="input-8"
      >
        <b-form-input
          id="input-8"
          v-model="form.outlet3Name"
          :state="outlet3NameFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="アウトレット3の名称を入力してください"
          :disabled="isDisabledChange"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-9"
        label="アウトレット4名称"
        label-for="input-9"
      >
        <b-form-input
          id="input-9"
          v-model="form.outlet4Name"
          :state="outlet4NameFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="アウトレット4の名称を入力してください"
          :disabled="isDisabledChange"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-10"
        label="制御用ID"
        label-for="input-10"
      >
        <b-form-input
          id="input-10"
          v-model="form.controlId"
          :state="controlIdFormState"
          type="url"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="制御用IDを入力してください"
          maxlength="100"
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内かつ半角英数字で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-11"
        label="パスワード"
        label-for="input-11"
      >
        <b-form-input
          id="input-11"
          v-model="form.controlPassword"
          :state="controlPasswordFormState"
          type="url"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="パスワードを入力してください"
          maxlength="100"
          required
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          50文字以内かつ半角英数字で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="b-form-group"
        id="input-group-12"
        label="備考"
        label-for="input-12"
      >
        <b-form-input
          id="input-12"
          v-model="form.remarks"
          :state="remarksFormState"
          aria-describedby="input-live-help input-live-feedback"
          trim
          placeholder="備考を入力してください"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          254文字以内で入力してください
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-13"
        label="使用停止フラグ"
        label-for="input-13"
        class="b-form-group"
      >
        <b-form-checkbox
          id="input-13"
          v-model="form.exclusionFlag"
          value="1"
          unchecked-value="0"
        ></b-form-checkbox>
      </b-form-group>

      <div style="display: inline-flex">
        <div v-if="mode === 'edit'">
          <b-button
            v-on:click="onSubmit"
            variant="primary"
            class="b-button-submit"
            :disabled="disabledFlag"
            >更新</b-button
          >
        </div>

        <div v-if="mode === 'create'">
          <b-button
            v-on:click="onSubmit"
            variant="primary"
            class="b-button-submit"
            :disabled="!inputFieldCheck"
            >登録</b-button
          >
        </div>

        <b-button type="reset" variant="primary" class="b-button-reset"
          >リセット</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  props: ["form", "mode", "id"],
  data() {
    return {
      manufacturer: [
        { text: "機種をお選びください", value: "" },
        { text: "大和電器 : 2口", value: "yamato_2" },
        { text: "明京電機 : 2口", value: "meikyo_2" },
        { text: "明京電機 : 4口", value: "meikyo_4" },
      ],
      show: true,
      isDisabled: true,
      outletCount: null,
      model: "",
      disabledFlag: false,
    };
  },

  // デフォルトで使用停止フラグをONにする
  created() {
    this.form.exclusionFlag = "1";
  },

  computed: {
    // 2口の場合は、アウトレット3,4名称を非活性にする
    isDisabledChange() {
      if (this.form.manufacturer == "meikyo_4") {
        return this.isDisabled == false;
      } else {
        return this.isDisabled == true;
      }
    },

    // MACアドレスが17文字以上入力されたときに、アラートを出すためにfalseを返す
    macAddressFormState() {
      // 文字数チェック
      if (this.form.macAddress.length <= 0) {
        return null;
      } else {
        if (this.form.macAddress.lengt > 17) {
          return false;
        } else {
          // 正規表現チェック
          const regex = new RegExp(/^[0-9a-fA-F]{2}(:[0-9a-fA-F]{2}){5}$/i);
          return regex.test(this.form.macAddress) ? null : false;
        }
      }
    },

    // IPアドレスが15文字以上入力されたときに、アラートを出すためにfalseを返す
    ipAddressFormState() {
      // 文字数チェック
      if (this.form.ipAddress.length <= 0) {
        return null;
      } else {
        if (this.form.ipAddress.lengt > 15) {
          return false;
        } else {
          // 正規表現チェック
          const regex = new RegExp(/^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/i);
          return regex.test(this.form.ipAddress) ? null : false;
        }
      }
    },

    // 拠点名が80文字以上入力されたときに、アラートを出すためにfalseを返す
    shopNameFormState() {
      return this.form.shopName.length <= 80 ? null : false;
    },

    // アウトレット1名称が50文字以上入力されたときに、アラートを出すためにfalseを返す
    outlet1NameFormState() {
      return this.form.outlet1Name.length <= 50 ? null : false;
    },

    // アウトレット2名称が50文字以上入力されたときに、アラートを出すためにfalseを返す
    outlet2NameFormState() {
      return this.form.outlet2Name.length <= 50 ? null : false;
    },

    // アウトレット3名称が51文字以上入力されたときに、アラートを出すためにfalseを返す
    outlet3NameFormState() {
      return this.form.outlet3Name.length <= 50 ? null : false;
    },

    // アウトレット4名称が51文字以上入力されたときに、アラートを出すためにfalseを返す
    outlet4NameFormState() {
      return this.form.outlet4Name.length <= 50 ? null : false;
    },

    // 制御用IDが51文字以上入力されたときに、アラートを出すためにfalseを返す
    controlIdFormState() {
      if (
        this.form.controlId.match(/^[A-Za-z0-9]*$/) &&
        this.form.controlId.length <= 50
      ) {
        return null;
      } else {
        return false;
      }
    },

    // パスワードが51文字以上or半角英数字以外が入力されたときに、アラートを出すためにfalseを返す
    controlPasswordFormState() {
      if (
        this.form.controlPassword.match(/^[A-Za-z0-9]*$/) &&
        this.form.controlPassword.length <= 50
      ) {
        return null;
      } else {
        return false;
      }
    },

    // 備考が255文字以上入力されたときに、アラートを出すためにfalseを返す
    remarksFormState() {
      return this.form.remarks.length <= 254 ? null : false;
    },

    // 入力チェック結果
    inputFieldCheck() {
      return this.macAddressFormState == null &&
        this.ipAddressFormState == null &&
        this.shopNameFormState == null &&
        this.outlet1NameFormState == null &&
        this.outlet2NameFormState == null &&
        this.outlet3NameFormState == null &&
        this.outlet4NameFormState == null &&
        this.controlIdFormState == null &&
        this.controlPasswordFormState == null &&
        this.remarksFormState == null && this.disabledFlag === false
    }
  },

  methods: {
    onSubmit(event) {
      this.disabledFlag = true;
      event.preventDefault();

      // 入力フィールドがすべてOKの場合は登録処理を行う
      if (
        this.macAddressFormState == null &&
        this.ipAddressFormState == null &&
        this.shopNameFormState == null &&
        this.outlet1NameFormState == null &&
        this.outlet2NameFormState == null &&
        this.outlet3NameFormState == null &&
        this.outlet4NameFormState == null &&
        this.controlIdFormState == null &&
        this.controlPasswordFormState == null &&
        this.remarksFormState == null
      ) {
        // 選択機種によってoutletCount,modelを設定する
        switch (this.form.manufacturer) {
          case "yamato_2":
            this.outletCount = 2;
            this.model = "yamato";
            break;
          case "meikyo_2":
            this.outletCount = 2;
            this.model = "meikyo";
            break;
          case "meikyo_4":
            this.outletCount = 4;
            this.model = "meikyo";
            break;
          default:
            break;
        }

        // 登録パラメータ
        const params = {
          macAddress: this.form.macAddress,
          ipAddress: this.form.ipAddress,
          outletCount: this.outletCount,
          model: this.model,
          manufacturer: this.form.manufacturer,
          shopName: this.form.shopName,
          outlet1Name: this.form.outlet1Name,
          outlet2Name: this.form.outlet2Name,
          outlet3Name: this.form.outlet3Name,
          outlet4Name: this.form.outlet4Name,
          controlId: this.form.controlId,
          controlPassword: this.form.controlPassword,
          remarks: this.form.remarks,
          exclusionFlag: this.form.exclusionFlag,
        };

        // 機器情報追加(新規登録)のとき
        if (this.mode == "create") {
          // 登録処理
          api
            .postRebooterCreate(params)
            .then((result) => {
              console.log("result", result)
              // 登録が成功したら一覧画面へ戻る
              if (result.status === 200) {
                // 一覧画面へ遷移
                this.disabledFlag = false;
                this.$router.push(`/rebooter/edit/${result.data.rebooter.rebooterId}`);
              } else {
                // 登録失敗
                this.disabledFlag = false;
                if (result.data.result === undefined) {
                  alert("リブーター登録が行えませんでした。時間をおいて再度実施をしてください。");
                } else {
                  alert(JSON.stringify(result.data.result));
                }
                console.log(result);
              }
            })
            .catch((err) => {
              // エラーログを出すこと
              this.disabledFlag = false;
              console.log(err);
              alert("リブーター登録が行えませんでした。時間をおいて再度実施をしてください。");
            });

          // 機器情報編集のとき
        } else if (this.mode == "edit") {
          // 更新処理
          api
            .putRebooterUpdate(this.id, params)
            .then((result) => {
              // 更新が成功したら操作画面へ戻る
              if (result.status === 200) {
                // 一覧画面へ遷移
                this.disabledFlag = false;
                this.$router.push(`/rebooter/control/${this.id}`);
              } else {
                // 登録失敗
                this.disabledFlag = false;
                if (result.data.result === undefined) {
                  alert("リブーター更新が行えませんでした。時間をおいて再度実施をしてください。");
                } else {
                  alert(JSON.stringify(result.data.result));
                }
                console.log(result);
              }
            })
            .catch((err) => {
              // エラーログを出すこと
              this.disabledFlag = false;
              console.log(err);
              alert("リブーター更新が行えませんでした。時間をおいて再度実施をしてください。");
            });
        }
      } else {
        // ここに入った場合、何もおきない
        this.disabledFlag = false;
      }
    },

    // 入力リセット処理
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.macAddress = "";
      this.form.ipAddress = "";
      this.form.sendMailAddress = "";
      this.form.manufacturer = "";
      this.form.shopName = "";
      this.form.outlet1Name = "";
      this.form.outlet2Name = "";
      this.form.outlet3Name = "";
      this.form.outlet4Name = "";
      this.form.controlId = "";
      this.form.controlPassword = "";
      this.form.remarks = "";
      this.form.exclusionFlag = "1";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // 4口⇒2口の場合は、アウトレット3,4名称のフォームを初期化する
    outletNameClear() {
      this.form.outlet3Name = "";
      this.form.outlet4Name = "";
    },

    // 戻る
    onBack() {
      this.$router.push(this.mode === 'create'? `/rebooter/view` : `/rebooter/control/${this.id}`);
    }

  },
};
</script>

<style scoped>
.b-form {
  margin: 20px 50px 20px;
}

.b-form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.b-button {
  margin: 10px 0px 0px 10px;
}

.b-button-submit {
  margin: 10px 0px 0px 0px;
}

.b-button-reset {
  margin: 10px 0px 0px 10px;
}

.b-button-back {
  margin: 0px;
  margin-bottom: 10px;
}

.mt-3 {
  margin: 20px 50px 20px;
}
</style>
